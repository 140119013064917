<template>
  <v-row justify="center">
    <v-col cols="12" sm="6" md="5" lg="4">
      <v-alert
        v-if="invalidToken"
        type="error"
        border="left"
        class="ma-3">
        {{ error }}
      </v-alert>

      <v-card
        v-if="!invalidToken"
        class="ma-3 mt-8"
      >
        <v-card-title>
          <span class="headline">
            {{ messages.title }}
          </span>
        </v-card-title>
        <v-card-text class="my-2">
          <v-form @submit.prevent="onSubmit">
            <v-text-field
              outlined
              v-model="newPassword"
              :label="messages.passwordLabel"
              type="password"
              :rules="[v => !!v || 'Ce champ est obligatoire']"
            />
            <v-btn
              block
              type="submit"
              color="primary"
              :loading="passwordLoading"
            >
              Valider
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

const cases = {
  'activate': {
    title: 'Activation de votre compte',
    passwordLabel: 'Choisissez un mot de passe',
    success: 'Votre compte a été activé avec succès.',
  },
  'reset': {
    title: 'Nouveau mot de passe',
    passwordLabel: 'Nouveau mot de passe',
    success: 'Mot de passe changé avec succès !',
  }
}

export default {
  data: () => ({
    token: '',
    initialLoading: true,
    passwordLoading: false,
    invalidToken: false,
    error: '',
    newPassword: '',
    messages: cases['reset']
  }),
  created () {},
  mounted () {
    if (!this.$route.query.token) {
      this.invalidToken = true
      this.error = "Un jeton est requis"
      return
    }
    if (this.$route.path === '/activate') {
      this.messages = cases['activate']
    }
    this.token = this.$route.query.token
    this.checkToken()
  },
  methods: {
    checkToken() {
      this.$apitator.post('/auth/reset-password/execute', {
        token: this.token,
        newPassword: 'xxx',
        checkValidity: true
      }).then(() => {
        this.initialLoading = false
      }).catch(() => {
        this.initialLoading = false
        this.invalidToken = true
        this.error = "Votre jeton est invalide ou expiré. Veuillez faire une nouvelle demande."
      })
    },
    onSubmit() {
      this.passwordLoading = true

      this.$apitator.post('/auth/reset-password/execute', {
        token: this.token,
        newPassword: this.newPassword
      }).then(res => {
        this.$store.dispatch('auth/login', {
          email: res.data.data.email,
          password: this.newPassword,
        })
          .then(() => {
            this.passwordLoading = false
            this.$store.commit('alert/add', {
              color: 'success',
              text: this.messages.success
            })

            this.$router.push({ name: 'Dashboard' })
          })
          .catch(err => {
            console.error(err)
            this.passwordLoading = false
            this.$store.commit('alert/add', {
              text: 'Impossible de se connecter',
              color: 'error'
            })
            this.$router.push({ name: 'Login' })
          })
      }).catch(() => {
        this.passwordLoading = false
        this.$store.commit('alert/add', {
          color: 'error',
          text: 'Erreur pendant le changement du mot de passe'
        })
      })
    }
  }
}
</script>
